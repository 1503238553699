import React from 'react';
import image1 from '../assets/images/piombaia1.png';
import image2 from '../assets/images/piombaia2.jpg';
import image3 from '../assets/images/piombaia3.jpg';
import image4 from '../assets/images/piombaia4.jpg';
import './GuestPage.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import { Guest } from '../apiClient';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { OpenAPI } from '../apiClient'; // replace './apiClient' with the correct path to the OpenAPI module
declare var google: any;
declare global {
    interface Window {
        initMap: () => void;
    }
}
const InfoPage = () => {
    const location = useLocation();
    const token = localStorage.getItem('token');
    if (token) {
        OpenAPI.HEADERS = {
            ...OpenAPI.HEADERS,
            'Authorization': `Bearer ${token}`
        };
    }
    const navigate = useNavigate();
    const loadData = (): Guest | undefined => {
        const data = localStorage.getItem('guest');
        return data ? JSON.parse(data) : null;
    };
    const guest = loadData();
    const handleNavigation = () => {
        if (guest && guest.rsvpCode) {
            navigate(`/guest/${guest.rsvpCode}`);
        } else {
            // Handle the case where guest or rsvpcode is not available
            console.error('Guest data or RSVP code is missing');
            // Optionally navigate to a different route or show an error message
        }
    };

    const handleNavigationToInfo = () => {
        navigate('/info');
    };

    useEffect(() => {
        window.initMap = function () {
            const location = { lat: 43.03991546174243, lng: 11.502888555389358 };
            const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
                zoom: 15,
                center: location,
                });
            // Add a marker
            new google.maps.Marker({
                position: location,
                map: map,
            });
        };

        // Check if the script has already been added
        if (!document.querySelector('script[src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD5aGj8THjUvPzHXf_yY4RKLIedjt_7_is&callback=initMap"]')) {
            // Load the Google Maps script
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD5aGj8THjUvPzHXf_yY4RKLIedjt_7_is&callback=initMap`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        }
    }, []);

    return (
        <div className="container-fluid guest-page my-font">
            <nav className="nav">
                <ul>
                    <li><button className={`nav-link ${location.pathname.startsWith('/guest') ? 'active' : ''}`} onClick={handleNavigation}>OSA</button></li>
                    <li><button className={`nav-link ${location.pathname.startsWith('/info') ? 'active' : ''}`} onClick={handleNavigationToInfo}>Information</button></li>
                </ul>
            </nav>
            <div className="card">
                <h1 className="title-font">Bröllopet i Montalcino</h1>
                <p>
                    <strong>Kära vänner och familj,</strong><br /><br />

                    Det är med stor glädje och förväntan vi vill dela vår kärlek med er och bjuda in er till vår bröllopshelg på Piombaia, en vingård utanför Montalcino i Toscana.<br /><br />

                    <strong>Datum:</strong> 13/09-2024 till 16/09-2024<br /><br />
                    <strong>Var:</strong> Montalcino, ett vackert vinlandskap i hjärtat av Toscana, är den perfekta platsen för vårt bröllop. Belägen strax utanför denna historiska by omges vingården Piombaia av ett landskap där det världsberömda Brunello-vinet frodas. Här, bland böljande kullar och vinrankor, kommer vi att säga våra löften inbäddade i en miljö som utstrålar romantik och tidlös skönhet. Denna magiska plats, med Montalcino i bakgrunden, erbjuder inte bara en fantastisk utsikt utan också en upplevelse som för alltid kommer att vara en del av vår gemensamma resa.<br /><br />
                    Vi ser fram emot att fira kärleken tillsammans med er under dessa dagar och vill dela vår plan för helgen med er:<br /><br />
                    <strong>Flyg och transfer:</strong> Ansvarar var och en för på egen hand. <br /><br />
                    <strong>Plats:</strong> Piombaia ligger cirka 2 timmar från Florens, som är den närmsta flygplatsen. Andra nära flygplatser är Pisa (2h30m) och Rom (2h40m). Det bästa sättet att komma till Piombaia är att hyra en bil, kanske tillsammans med andra gäster, för att underlätta transporten till och från vingården. Det går även att ta tåg till Siena och hyra bil därifrån. Vi kommer att hjälpa till med att samordna transfer till och från Piombaia för de som önskar.<br /><br />
                    <strong>Boende:</strong> Alla gäster kommer att bo på vingården Piombaia, där bröllopet äger rum. Vi har fixat detta arrangemang för er bekvämlighet. Kostnaden för boendet är 1500 kr per person och det är till oss ni betalar för boendet.<br /><br />
                    <strong>Vad ingår?</strong> Gemensam rehersal middag vid ankomsten på fredag, frukost på lördag, söndag, måndag. Samt all mat och dryck under festligheterna på lördagen. För övriga måltider finns det en restaurang på vingården.<br /> Det är även kort avstånd till den pitoreska byn Montalcino där det finns fanstiska utsikter, vin och mat.<br /><br />
                    
                </p>
                <div id="map" style={{ height: '400px', width: '100%' }}></div><br /><br />
                <p>
                    <h2>Schema</h2>
                    <strong>Fredag:</strong> En avslappnad och mysig kväll med en enklare middag klockan 19 för att starta helgen tillsammans.<br /><br />

                    <strong>Lördag:</strong> Dagen börjar med en gemensam frukost. Vid 16:00 äger vigseln rum, följt av ett mingel innan vi samlas för kvällens middag klockan 19:00. <br /><strong>Klädkod</strong> vid vigsel är kavaj i övrigt är klädkoden fri.<br /><br />

                    <strong>Söndag:</strong> Efter en gemensam frukost är dagen fri för aktiviteter. Ni är välkomna att njuta av omgivningen, bada eller utforska Montalcino på egen hand.<br /><br />

                    <h2>Övrig information:</h2>
                    Vi tycker verkligen om barn, men för att kunna njuta av vår stora dag tillsammans med alla vuxna gäster, skulle vi vilja be er att låta barnen stanna hemma under denna helgen. Vi hoppas ni förstår och uppskattar ert stöd för att göra vår dag extra speciell.<br /><br />
                    Vi önskar inga gåvor, er närvaro är den finaste presenten.
                    <br /><br />
                    Vänligen bekräfta ert deltagande senast <strong>första maj 2024</strong>. Betalning av boendet önskas senast första juni via banköverföring till 8327-9, 764 104 224-2 eller swisha till 0708421610.
                    <br /><br />
                    Vid övriga frågor kontakta <strong>info@romanochrebecca2024.se</strong> eller privat till någon av oss.
                    <br /><br />
                    Om någon önskar hålla tal eller liknande, kontakta gärna Josefin Stenfeldt på <strong>Josefin.stenfedt@gmail.com</strong> som hjälper oss att planera.
                    <br /><br />
                    Vi ser fram emot att skapa minnen tillsammans och dela denna speciella stund med er. Tack för att ni är en del av vår resa.
                </p>
                <img src={image2} alt="Image 1" />
                <img src={image3} alt="Image 2" />
                <img src={image1} alt="Image 3" />
                <img src={image4} alt="Image 4" />
            </div>
        </div>
    );
};

export default InfoPage;